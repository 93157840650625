import debounce from 'debounce'

import {SAVE_ACCOMMODATIONS} from '../constants'
import {updateParticipant} from '../actions/apiActions'
import {extraTimePerQuizValid} from '../util/extraTimePerQuizValid'
import {isValidNumberValue} from '../util/isValidNumberValue'

const saveAccommodations = debounce(store => {
  const state = store.getState()

  const {
    giveExtraTimeSettings,
    giveExtraAttemptsSettings,
    reduceAnswerChoicesSettings,
    moderation,
  } = state
  const {participantId} = giveExtraTimeSettings

  const participantPayload = buildParticipantPayload(
    giveExtraTimeSettings,
    giveExtraAttemptsSettings,
    reduceAnswerChoicesSettings,
    moderation.participants[participantId],
  )

  if (Object.keys(participantPayload).length === 0) {
    return
  }

  store.dispatch(
    updateParticipant({
      participantId,
      participant: participantPayload,
    }),
  )
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default store => next => action => {
  if (
    action.type === SAVE_ACCOMMODATIONS &&
    store.getState().features?.includes('add_time_to_existing_quiz_sessions')
  ) {
    saveAccommodations(store)
  }
  return next(action)
}

export function buildParticipantPayload(
  giveExtraTimeSettings,
  giveExtraAttemptsSettings,
  reduceAnswerChoicesSettings,
  participant,
) {
  const {disableTimerQuiz, extraTimeEnabledQuiz, extraTimeInSecondsQuiz} = giveExtraTimeSettings

  const {extraAttemptsQuiz} = giveExtraAttemptsSettings

  const {reduceChoicesEnabledQuiz} = reduceAnswerChoicesSettings

  const {
    disableTimerQuiz: oldDisableTimerQuiz,
    extraAttemptsQuiz: oldExtraAttemptsQuiz,
    extraTimeEnabledQuiz: oldExtraTimeEnabledQuiz,
    extraTimeInSecondsQuiz: oldExtraTimeInSecondsQuiz,
    reduceChoicesEnabledQuiz: oldReduceChoicesEnabledQuiz,
  } = participant

  const participantPayload = {}

  if (extraTimePerQuizValid(giveExtraTimeSettings)) {
    if (disableTimerQuiz !== oldDisableTimerQuiz) {
      Object.assign(participantPayload, {disable_timer: disableTimerQuiz})
    }

    if (extraTimeEnabledQuiz !== oldExtraTimeEnabledQuiz) {
      Object.assign(participantPayload, {extra_time_enabled: extraTimeEnabledQuiz})
    }

    if (extraTimeInSecondsQuiz !== oldExtraTimeInSecondsQuiz) {
      Object.assign(participantPayload, {extra_time_in_seconds: extraTimeInSecondsQuiz})
    }

    if (reduceChoicesEnabledQuiz !== oldReduceChoicesEnabledQuiz) {
      Object.assign(participantPayload, {reduce_choices_enabled: reduceChoicesEnabledQuiz})
    }
  }

  if (isValidNumberValue(extraAttemptsQuiz) && extraAttemptsQuiz !== oldExtraAttemptsQuiz) {
    Object.assign(participantPayload, {extra_attempts: extraAttemptsQuiz})
  }

  return participantPayload
}
