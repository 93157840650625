import {
  ENROLLMENT_UPDATED,
  MODERATE_TRAY_CLOSED,
  OPEN_MODERATE_TRAY,
  UPDATE_COURSE_ACCOMMODATION_SETTINGS,
  OPEN_ACCOMMODATION_MODAL,
  ACCOMMODATION_MODAL_CLOSED,
  UPDATE_ACCOMMODATION,
} from '../constants'
import slice from '../util/slice'

const initialState = {
  contextId: null,
  disableTimer: false,
  extraTimeEnabled: false,
  extraTimeInSeconds: null,
  extraTimeUnit: null,
  extraTimeValue: null,
  increaseTimeAllowance: false,
  timerMultiplierEnabled: false,
  timerMultiplierValue: null,
  reduceChoicesEnabled: false,
  participantId: null,
  userId: null,
  name: null,
  modalState: false,
  applyToCurrentAttempts: true,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ENROLLMENT_UPDATED:
      return (() => {
        const {contextId, userId} = state
        if (action.contextId !== contextId || action.userId !== userId) {
          return state
        }

        return courseAccommodationSettings({
          ...state,
          ...action.enrollment,
          participantId: action.participantId,
        })
      })()

    case MODERATE_TRAY_CLOSED:
      // if courseAccommodationSettings store is driving AccommodationModal
      // (instead of Moderation tray)
      // we don't want to clean up state in response to Moderation tray events
      return state.modalState ? state : initialState

    case OPEN_MODERATE_TRAY:
      return courseAccommodationSettings({
        ...state,
        ...action.participant,
        participantId: action.participant.id,
      })

    // same as OPEN_MODERATE_TRAY, but we will change
    // OPEN_MODERATE_TRAY later
    case OPEN_ACCOMMODATION_MODAL:
      return courseAccommodationSettings({
        ...state,
        ...action.participant,
        participantId: action.participant.id,
        modalState: true,
      })

    case ACCOMMODATION_MODAL_CLOSED:
      return courseAccommodationSettings({
        ...state,
        modalState: false,
      })

    case UPDATE_ACCOMMODATION:
      return courseAccommodationSettings({
        ...state,
        ...action.settings,
      })

    case UPDATE_COURSE_ACCOMMODATION_SETTINGS:
      return {
        ...state,
        ...action.settings,
      }

    default:
      return state
  }
}

function courseAccommodationSettings(participant) {
  const settings = slice(participant, Object.keys(initialState))
  return {
    ...settings,
    ...extraTimeSettings(settings),
    ...timerMultiplierSettings(settings),
    ...answerChoiceSettings(settings),
  }
}

function extraTimeSettings(settings) {
  const extraTimeInSeconds = Number(settings.extraTimeInSeconds || 0)
  const extraTimeUnit = unitFor(extraTimeInSeconds) || settings.extraTimeUnit
  const extraTimeValue = String(convertSeconds(extraTimeInSeconds, extraTimeUnit))
  return {extraTimeUnit, extraTimeValue}
}

function timerMultiplierSettings(settings) {
  const timerMultiplierValue = Number(settings.timerMultiplierValue || 1)
  return {timerMultiplierValue: String(timerMultiplierValue)}
}

function answerChoiceSettings(settings) {
  const reduceChoicesEnabled = Boolean(settings.reduceChoicesEnabled || false)
  return {reduceChoicesEnabled: reduceChoicesEnabled}
}

function convertSeconds(value, unit) {
  switch (unit) {
    case 'minutes':
      return Math.round(value / 60)
    case 'hours':
      return Math.round(value / 3600)
    default:
      throw new Error(`Invalid unit: ${unit}`)
  }
}

function unitFor(seconds) {
  return seconds !== 0 && seconds % 3600 === 0 ? 'hours' : 'minutes'
}
