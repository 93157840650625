import {generateExternalStyle} from './externalStyles'

const generateStyle = (componentTheme, props) => {
  const containerStyle = props.iceTopNavBarEnabled
    ? {
        display: 'inline',
      }
    : {
        flex: '1',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: componentTheme.returnButtonContainerRightMargin,
      }
  return {
    returnButtonContainer: containerStyle,
    ...generateExternalStyle(),
  }
}

export default generateStyle
