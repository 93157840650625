import debounce from 'debounce'

import {UPDATE_COURSE_ACCOMMODATION_SETTINGS} from '../constants'
import {updateEnrollment} from '../actions/apiActions'
import {updateParticipant} from '../actions/apiActions'
import {buildParticipantPayload} from './saveAccommodationsMiddleware'

const saveCourseAccommodationSettings = debounce(store => {
  const state = store.getState()
  const {courseAccommodationSettings, giveExtraTimeSettings} = state
  if (!courseAccommodationSettingsValid(courseAccommodationSettings)) {
    return
  }

  const {contextId, userId} = courseAccommodationSettings
  const participantId =
    courseAccommodationSettings.participantId || giveExtraTimeSettings.participantId
  const enrollment = formatCourseAccommodationSettings(courseAccommodationSettings)
  store.dispatch(updateEnrollment({contextId, participantId, userId, enrollment}))

  if (
    state.features?.includes('atteq_apply_changes_to_current_quiz_attempts') &&
    courseAccommodationSettings.applyToCurrentAttempts
  ) {
    // Update current attempts
    const participantPayload = buildParticipantPayload(
      courseAccommodationSettings.disableTimer
        ? {
            disableTimerQuiz: true,
          }
        : courseAccommodationSettings.extraTimeEnabled
          ? {
              extraTimeEnabledQuiz: false,
              extraTimeInSecondsQuiz: 0,
            }
          : {},
      {},
      {
        reduceChoicesEnabledQuiz: courseAccommodationSettings.reduceChoicesEnabled,
      },
      state.moderation.participants[participantId],
    )

    if (Object.keys(participantPayload).length === 0) {
      return
    }

    store.dispatch(
      updateParticipant({
        participantId,
        participant: participantPayload,
      }),
    )
  }
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default store => next => action => {
  if (action.type === UPDATE_COURSE_ACCOMMODATION_SETTINGS) {
    saveCourseAccommodationSettings(store)
  }
  return next(action)
}

function courseAccommodationSettingsValid(settings) {
  const {extraTimeEnabled, extraTimeValue, timerMultiplierEnabled, timerMultiplierValue} = settings
  if (extraTimeEnabled && !isNumeric(extraTimeValue)) {
    return false
  }

  if (timerMultiplierEnabled && !isNumeric(timerMultiplierValue)) {
    return false
  }

  return true
}

function isNumeric(number) {
  // Number accepts empty strings and strings with trailing dot
  return !Number.isNaN(Number(number)) && /[^.]$/.test(number)
}

function formatCourseAccommodationSettings(settings) {
  /* eslint-disable immutable/no-mutation */
  const mapping = {
    disableTimer: 'disable_timer',
    extraTimeEnabled: 'extra_time_enabled',
    increaseTimeAllowance: 'increase_time_allowance',
    timerMultiplierEnabled: 'timer_multiplier_enabled',
    reduceChoicesEnabled: 'reduce_choices_enabled',
  }

  const formatted = {}
  Object.keys(mapping)
    .filter(key => Object.prototype.hasOwnProperty.call(settings, key))
    .forEach(key => {
      formatted[mapping[key]] = settings[key]
    })

  const extraTimeValue = Number(settings.extraTimeValue)
  if (!Number.isNaN(extraTimeValue)) {
    formatted.extra_time_in_seconds = extraTimeInSeconds(extraTimeValue, settings.extraTimeUnit)
  }

  const timerMultiplierValue = Number(settings.timerMultiplierValue)
  if (!Number.isNaN(timerMultiplierValue)) {
    formatted.timer_multiplier_value = timerMultiplierValue
  }

  return formatted
  /* eslint-enable immutable/no-mutation */
}

function extraTimeInSeconds(value, unit) {
  switch (unit) {
    case 'minutes':
      return value * 60
    case 'hours':
      return value * 3600
    default:
      throw new Error(`Invalid unit: ${unit}`)
  }
}
