/** @jsx jsx */
import {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import t from 'format-message'

import {Billboard} from '@instructure/ui-billboard'
import {IconQuizLine} from '@instructure/ui-icons'
import {jsx} from '@instructure/emotion'
import {withStyleOverrides} from '@instructure/quiz-core'

import Page from '../components/page/Page'
import QuizHeader from '../components/header/QuizHeader'
import ReturnButtonContainer from '../components/header/ReturnButtonContainer'
import {IconUnsupportedPanda} from './images/IconUnsupportedPanda'

import generateStyle from './styles'
import generateComponentTheme from './theme'

@withStyleOverrides(generateStyle, generateComponentTheme)
export class QuizUnavailable extends Component {
  static displayName = 'QuizUnavailable'
  static componentId = `Quizzes${this.displayName}`
  static propTypes = {
    message: PropTypes.string,
    returnToUrl: PropTypes.string.isRequired,
    iceTopNavBarEnabled: PropTypes.bool,
    platform: PropTypes.string,
    styles: PropTypes.object,
  }

  get isRLDBLaunch() {
    const {rldbLaunch} = this.props
    return rldbLaunch ? rldbLaunch.toLowerCase() === 'true' : false
  }

  renderHeader() {
    if (this.props.iceTopNavBarEnabled) {
      return (
        <QuizHeader
          returnToUrl={this.props.returnToUrl}
          iceTopNavBarEnabled
          actionItems={[
            <ReturnButtonContainer
              id="ReturnButton"
              key="ReturnButton"
              returnToUrl={this.props.returnToUrl}
              platform={this.props.platform}
              rldbLaunch={this.isRLDBLaunch}
              rldbCanExit={true}
              iceTopNavBarEnabled
            />,
          ]}
        />
      )
    }

    return (
      <QuizHeader returnToUrl={this.props.returnToUrl}>
        <div css={this.props.styles.quizIcon}>
          <IconQuizLine color="primary" />
        </div>
        <ReturnButtonContainer
          returnToUrl={this.props.returnToUrl}
          platform={this.props.platform}
          rldbLaunch={this.isRLDBLaunch}
          rldbCanExit={true}
        />
      </QuizHeader>
    )
  }

  render() {
    return (
      <Page header={this.renderHeader()}>
        <Billboard
          margin="xx-large 0 0 0"
          heading={t('Quiz Unavailable')}
          message={this.props.message}
          hero={size => <IconUnsupportedPanda size={size} />}
        />
      </Page>
    )
  }
}

export function mapStateToProps(state) {
  const {context, features} = state
  return {
    platform: context.platform,
    rldbLaunch: context.rldbLaunch,
    returnToUrl: context.returnToUrl,
    iceTopNavBarEnabled: features.includes('ice_top_nav_bar'),
  }
}

export default connect(mapStateToProps, null)(QuizUnavailable)
