/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import {jsx, Global} from '@instructure/emotion'
import {withStyleOverrides} from '@instructure/quiz-core'
import t from 'format-message'

import generateStyle from './styles'
import generateComponentTheme from './theme'

@withStyleOverrides(generateStyle, generateComponentTheme)
export default class PageHeader extends React.Component {
  static displayName = 'PageHeader'
  static componentId = `Quizzes${this.displayName}`
  static propTypes = {
    children: PropTypes.node.isRequired,
    isNewTopNavBar: PropTypes.bool,
    makeStyles: PropTypes.func,
    styles: PropTypes.object,
  }

  static defaultProps = {
    isNewTopNavBar: false,
  }

  componentDidMount() {
    this.props.makeStyles()
  }

  componentDidUpdate() {
    this.props.makeStyles()
  }

  render() {
    return (
      <header className="header" css={this.props.styles.header} aria-label={t('Quiz header')}>
        <Global styles={this.props.styles.globalStyles} />
        <div css={this.props.styles.headerContent}>{this.props.children}</div>
      </header>
    )
  }
}
